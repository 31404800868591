@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #edebe6;
  padding-top: 100px; /* Add some padding to the top of the entire app */
}

p{
  font-family: "Source Code Pro";
  color:#606060;
}

h1{
  font-family: "Source Code Pro";
  color:black;
}

h2{
  font-family: "Source Code Pro";
}

h3{
  font-family: "Source Code Pro";
}

h4{
  font-family: "Source Code Pro";  
}


.App-header {
  width: 100%;
}

.orange-text {
  color: #FF5F05;
}

.container {
  max-width: 750px;
  margin: auto;
  text-align: left;
  padding: 20px;
  border-radius: 8px;
  background-color: #edebe6;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-info {
  flex: 1; /* Allow profile info to take available space */
  margin-right: 20px; /* Add some space between the info and the image */
}

.profile h1 {
  font-size: 2em;
  margin: 0;
}

.profile p {
  margin: 10px 0;
  color: #606060;
}

/* top of the screen name */
.profile-text{
  color: #606060;
}

.intro-text{
  color:#606060;
  font-family: 'Source Code Pro';
}

.profile-text p{
  color:#606060;
}

.profile-picture {
  width: 150px; /* Adjust size as needed */
  height: auto;
  padding-left: 15px;
  border-radius: 50%;
}

.social-icons a {
  margin-right: 10px;
  text-decoration: none;
  font-size: 1.5em;
  color: #606060;
}

.social-icons a:hover {
  color: #0073e6;
}

.work, .writings {
  margin-top: 20px;
}

.work h2, .writings h2 {
  font-size: 1.5em;
}

.work ul, .writings ul {
  list-style-type: none;
  padding: 0;
}

.work li, .writings li {
  margin: 5px 0;
}

hr {
  border: none;
  height: 1px;
  background: none;
  background-image: linear-gradient(to right, #606060 50%, rgba(255, 0, 0, 0) 0%);
  background-position: top left;
  background-size: 10px 1px; /* Adjust the size: 10px is the spacing between dots */
  background-repeat: repeat-x;
  margin: 20px auto; /* Center the hr element */
}

footer {
  background-color: #edebe6;
  text-align: center;
  padding: 10px 0;
}

a{
  color:#FF5F05;
}