/* DropdownCard.css */
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');


.dropdown-card {
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .dropdown-card-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-card-header h3 {
    margin: 0;
  }

  .dropdown-card-content{
    color:aqua;
  }

  #openButton{
    color: #606060;
    font-size:17px;
  }
  
  h3{
    color:#606060;
  }
