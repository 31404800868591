@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');


.project-card {
    background-color: #edebe6;
    border-radius: 8px;
    padding: 0px;
    margin-bottom: 20px;
    color: #000;
    cursor: pointer;
  }
  
  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .project-header h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .project-header span {
    font-size: 1.2em;
  }
  
  .project-card p {
    margin: 0;
    font-size: 0.9em;
  }
  
  .description{
    padding-left:20px;
    font-family: "Source Code Pro";
    color:#606060;
  }

  #button{
    color:#606060;
    font-size:17px;
  }